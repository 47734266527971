// Header.js
import { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-scroll";
import Logo from "../assets/logo.png"; // Path to your logo image

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: "#fff", boxShadow: "none" }}
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton edge="start" color="black" aria-label="menu">
          <img src={Logo} alt="Yemidyn Logo" style={{ height: "40px" }} />
        </IconButton>

        <IconButton edge="end" color="black" onClick={handleDrawerOpen}>
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          PaperProps={{
            style: {
              backgroundColor: "#000", // Background color of the drawer
              color: "#fff", // Text color in the drawer
            },
          }}
        >
          <div style={{ width: 250 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "16px",
              }}
            >
              <Typography variant="h6" style={{ color: "#fff" }}>
                Menu
              </Typography>
              <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Divider style={{ backgroundColor: "#fff" }} />
            <List>
              <ListItem button onClick={handleDrawerClose}>
                <ListItemText>
                  <Link
                    to="about"
                    smooth={true}
                    duration={500}
                    style={{ color: "#fff" }}
                  >
                    About Us
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem button onClick={handleDrawerClose}>
                <ListItemText>
                  <Link
                    to="products"
                    smooth={true}
                    duration={500}
                    style={{ color: "#fff" }}
                  >
                    Our Products
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem button onClick={handleDrawerClose}>
                <ListItemText>
                  <Link
                    to="testimonials"
                    smooth={true}
                    duration={500}
                    style={{ color: "#fff" }}
                  >
                    Testimonials
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={handleDrawerClose}
                component="a"
                href="https://wa.me/c/2348169469300"
              >
                <ListItemText style={{ color: "#fff" }}>Order</ListItemText>
              </ListItem>
            </List>
          </div>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
