import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import hero1 from "../assets/hero2.jpg"; // Replace with the correct paths to your images
import hero2 from "../assets/hero22.jpg";

const HeroSectionContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#f5f5f5",
  position: "relative",
  overflow: "hidden",
  textAlign: "center",
  padding: "20px",
});

const HeroImage = styled(Box)(({ image }) => ({
  width: "100%",
  height: "100%",
  backgroundImage: `url(${image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "absolute",
  top: 0,
  left: 0,
  transition: "opacity 2s ease-in-out",
  opacity: 0,
  "&.active": {
    opacity: 1,
  },
}));

const HeroContent = styled(Box)({
  position: "relative",
  zIndex: 2,
  color: "#fff",
});

const HeroSection = () => {
  const [activeImage, setActiveImage] = useState(0);
  const images = [hero1, hero2];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage((prevImage) => (prevImage + 1) % images.length);
    }, 4000); // Change image every 4 seconds
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section id="home">
      <HeroSectionContainer>
        {images.map((image, index) => (
          <HeroImage
            key={index}
            image={image}
            className={index === activeImage ? "active" : ""}
          />
        ))}
        <HeroContent></HeroContent>
      </HeroSectionContainer>
    </section>
  );
};

export default HeroSection;
