import React from "react";
import {
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";

// Import images
import caps from "../assets/products/caps.jpg";
import hoodies from "../assets/products/hoodies.jpg";
import tshirts from "../assets/products/tshirts.jpg";
import sweatbands from "../assets/products/sweatbands.jpg";
import socks from "../assets/products/socks.jpg";
import comingSoon from "../assets/products/coming-soon.jpg";

// Define product data
const products = [
  { id: 1, title: "Caps", image: caps },
  { id: 2, title: "Hoodies", image: hoodies },
  { id: 3, title: "T-Shirts", image: tshirts },
  { id: 4, title: "Sweatbands", image: sweatbands },
  { id: 5, title: "Socks", image: socks },
  { id: 6, title: "Sweatshirts", image: comingSoon },
];

// Styled components
const Section = styled(Box)({
  padding: "40px 20px",
  backgroundColor: "#f5f5f5",
  textAlign: "center",
});

const ProductCard = styled(Card)({
  borderRadius: "15px",
  overflow: "hidden",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const ProductShowcase = () => {
  return (
    <Section id="products">
      <Typography variant="h4" component="h2" gutterBottom>
        Our Products
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
            <ProductCard>
              <CardMedia
                component="img"
                alt={product.title}
                height="200"
                image={product.image}
              />
              <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                  {product.title}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href="https://wa.me/c/2348169469300"
                  startIcon={<i className="fas fa-shopping-cart"></i>}
                >
                  Order Now
                </Button>
              </CardContent>
            </ProductCard>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default ProductShowcase;
